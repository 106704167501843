.ticket-form-container{
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(255, 0, 34, 0.2);
    border-radius: 20px;
}

.ticket-form-content-container{
    margin-bottom: 20px;
    padding: 0 20px;
    border-radius: 10px;
    position: relative;
}

.ticket-form-content-left{
    min-width: 300px; 
}

.ticket-form-content-right{
    min-width: 300px; 
}


@media screen and (min-width: 780px) {
    .ticket-form-content-container{
        display: grid;
        grid-template-columns:  repeat(2,1fr);
        gap: 20px;
    }
}  