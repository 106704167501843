.step3-form-container{
    display: flex;
    flex-direction: column;
}
.step3-form-title{
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 32px;
}

.step3-form-content-container{
    
}

.step3-form-content-left{
    min-width: 300px; 
}

.step3-form-content-right{
    min-width: 300px; 
}


@media screen and (min-width: 780px) {
    .step3-form-content-container{
        display: grid;
        grid-template-columns:  repeat(2,1fr);
        gap: 20px;
    }
}  