.search-form {
    display: flex;
    align-items: center;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    padding: 5px;
    gap: 5px;
  }
  
  .search-button {
    box-sizing: border-box;
    /* border: 2px solid transparent; */
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    height: 40px;
    padding: 0px 10px;
    border-radius: 20px;
    color: #202C41;
    background-color: #F1360A;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: opacity 200ms ease 0s;
  }
  
  .search-icon {
    width: 20px;
    height: 20px;
  }
  