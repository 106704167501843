/* App.css (ou tout autre fichier CSS global) */

/* Style pour la grille principale du calendrier */
.react-calendar {
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Style pour les boutons de navigation */
  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
  }
  
  /* Style pour les jours de la semaine */
  .react-calendar__month-view__weekdays {
    display: flex;
    justify-content: space-around;
    padding: 8px 0;
    background-color: #f0f0f0;
  }
  
  /* Style pour chaque jour de la semaine */
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    color: #666;
  }
  
  /* Style pour les cellules du calendrier */
  .react-calendar__tile {
    padding: 10px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  
  /* Style pour les jours du mois */
  .react-calendar__month-view__days__day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  /* Style pour les jours du mois désactivés */
  .react-calendar__month-view__days__day--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Style pour le jour actuellement sélectionné */
  .react-calendar__month-view__days__day--active {
    background-color: #F1360A;
    border-radius: 50%;
    color: white;
  }
  