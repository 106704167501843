.date-recurrent-form-container{
    display: flex;
    flex-direction: column;
  }
  
  .date-recurrent-form-content-container{
    
  }
  
  .date-recurrent-form-content-left{
    min-width: 300px; 
  }
  
  .date-recurrent-form-content-right{
    min-width: 300px; 
  }
  
  .details-separator{
    margin : 40px 0 10px 0;
    border : solid 1px rgba(128, 128, 128, 0.589)
  }
  
  @media screen and (min-width: 780px) {
    .date-recurrent-form-content-container, .date-recurrent-form-time-grid{
        display: grid;
        grid-template-columns:  repeat(2,1fr);
        gap: 20px;
    }
  }  