.event-form-container{
    display: flex;
    flex-direction: column;
    min-width: 500px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
    border-radius: 20px;
}

.event-form-btn-container{
    display: flex ;
    justify-content: center;
    align-items: center;
    padding: 20px ;
    gap: 50%;
}