.step2-form-container{
    display: flex;
    flex-direction: column;
}
.step2-form-title{
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 32px;
}

.step2-form-content-container{
    
}

.step2-form-content-left{
    min-width: 300px; 
}

.step2-form-content-right{
    min-width: 300px; 
}

.details-separator{
    margin : 40px 0 10px 0;
    border : solid 1px rgba(128, 128, 128, 0.589)
}

@media screen and (min-width: 780px) {
    .step2-form-content-container, .step2-form-date-ponctuel-container,.step2-form-time-grid{
        display: grid;
        grid-template-columns:  repeat(2,1fr);
        gap: 20px;
    }
}  