.step1-form-container{
    display: flex;
    flex-direction: column;
}

.step1-form-title{
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 32px;
}

.step1-form-img{
    max-width: 100%;
    position: relative;
    right: 0;
    background-size: cover;
    min-height: 200px;
    border-radius: 10px;
    margin-bottom: 20px;
}


.step1-form-content-container{
display: grid;
grid-template-columns: repeat(2,1fr);
gap: 20px;
}