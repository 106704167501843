
.event-filter-container{
    width:100%;
    display: flex;
    flex-direction: column;

 
}
@media screen and (max-width: 720px) {
    .event-filter-container{
        display:none;
    }
  }
.event-filter-form-container{
    width:100%;
    background-color: white;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
    padding :0 10px 10px 10px;
}


.section-name{
    border-bottom: solid 2px gray;
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 8px;
    margin-bottom: 6px;
}

.cat-list-container{
    width:100%;
    background-color: white;
    box-shadow: 0px 0px 5px 5px rgba(66, 64, 64, 0.1);
    text-align: left;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px
}