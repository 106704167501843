.scheduler-container{
  width :100%;
  display: flex;
  flex-direction: column;
  gap : 20px
}

.fc {
  width :100%;
  height: 100%;
}

.fc-day-past {
  background-color: #eaeaea; /* Couleur de fond pour les jours passés */
}

.fc-daygrid-day, .fc-timegrid-slot { /* Cette règle cible à la fois les cellules des vues mensuelle et hebdomadaire */
  position: relative; /*Nécessaire pour le positionnement absolu des enfants*/
}

.fc-daygrid-day:hover::after, .fc-timegrid-slot:hover::after {
  content: "+Programme";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2px 5px;
  border-radius: 4px;
  color: blue;
  white-space: nowrap;
}